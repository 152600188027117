import { BoxProps, VStack, Text, useColorMode, HStack, Stack } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import { DEFAULT_CHAIN_ID, TRANS_TABLE_CONFIG } from '../../../config/bizConfig';
import { useSearchParams } from '../../../hooks/useSearchParams';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import {
    getIziSwapTransRecord,
    RequestIziSwapTransRecord,
    ResponseIziTransRecord,
    TransRecordTypeEnum,
    TransRecordTypeMapping,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { TransactionsSetting } from '../../../state/models/dashboard/dashboard';
import { i_text_copy } from '../../../style';
import { ChainId } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { isNumeric } from '../../../utils/valid';
import { TransactionList } from '../../components/TransactionList';
import { TransactionOrderList } from '../../components/TransactionOrderList';

type TransactionsBlockProps = {
    variant?: any;
} & BoxProps;

export const TransactionBlock: React.FC<TransactionsBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const breakLine = 3;
    const [setting, setSetting] = useSetState<TransactionsSetting>({
        page: 1,
        pageSize: 10,
        filterType: undefined,
    });

    const [transData, setTransData] = useSetState<MultiPageData<ResponseIziTransRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    const searchParams = useSearchParams();
    const tokenAddress = searchParams.get('tokenAddress') || undefined;
    const chainId = isNumeric(searchParams.get('chainId')) ? Number(searchParams.get('chainId')) : DEFAULT_CHAIN_ID;

    useEffect(() => {
        // TODO: limit date range?
        const queryParams: RequestIziSwapTransRecord = {
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            token_addr: tokenAddress,
            type: setting.filterType,
        };
        getIziSwapTransRecord(queryParams).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            const limitTotalPage = Math.min(totalPage, TRANS_TABLE_CONFIG.MAX_LIST_ITEM / setting.pageSize);
            setTransData({ data, totalPage: limitTotalPage });
        });
    }, [setting, chainId, tokenAddress]);

    const typeBt = (e: string, i: number) => {
        const isSelected = TransRecordTypeMapping[e] === setting.filterType;
        return isMobile ? (
            <CustomButton
                variant={isSelected ? 'purple' : 'outlinePurple'}
                onClick={() =>
                    setSetting({
                        ...setting,
                        filterType: TransRecordTypeMapping[e],
                        page: 1,
                    })
                }
                bg={isSelected ? '#7342F7' : colorTheme('#F4F3F3', '#17171B')}
                _focus={{}}
                _hover={{}}
                border={isSelected ? '' : colorTheme('1px solid #E3E3E3', '1px solid #252426')}
                borderRadius="6px"
                color={isSelected ? '#FFFFFF' : '#A8AAB3'}
                key={i}
                ml={i === 0 ? '0px' : 'unset'}
                text={e}
                h="39px"
                w={{ base: '111px', sm: 'unset' }}
                px={{ base: '1px', sm: '10px' }}
                fontSize="12px"
            />
        ) : (
            <CustomButton
                variant={TransRecordTypeMapping[e] === setting.filterType ? 'purple' : 'outlinePurple'}
                onClick={() =>
                    setSetting({
                        ...setting,
                        filterType: TransRecordTypeMapping[e],
                        page: 1,
                    })
                }
                borderRadius="3px"
                key={i}
                ml={i === 0 ? '0px' : 'unset'}
                text={e}
                h="30px"
                w={{ base: '33%', sm: 'unset' }}
                px={{ base: '1px', sm: '10px' }}
                fontSize="12px"
            />
        );
    };

    return isMobile ? (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '20px !important', sm: '60px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Transactions
                </Text>
            )}
            <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px">
                <HStack spacing={{ base: '8px', sm: '20px' }}>
                    {Object.keys(TransRecordTypeMapping)
                        .slice(0, breakLine)
                        .map((e: string, i: number) => {
                            return typeBt(e, i);
                        })}
                </HStack>
                <HStack spacing={{ base: '8px', sm: '20px' }}>
                    {Object.keys(TransRecordTypeMapping)
                        .slice(breakLine, Object.keys(TransRecordTypeMapping).length)
                        .map((e: string, i: number) => {
                            return typeBt(e, i);
                        })}
                </HStack>
            </Stack>
            <Stack minH="831px">
                {setting.filterType === TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER ||
                setting.filterType === TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER ? (
                    <TransactionOrderList
                        setting={setting}
                        pageData={transData}
                        setCurrentPage={(page: number) => setSetting({ page })}
                        setPageSize={(pageSize: number) => setSetting({ pageSize })}
                    ></TransactionOrderList>
                ) : (
                    <TransactionList
                        setting={setting}
                        pageData={transData}
                        setCurrentPage={(page: number) => setSetting({ page })}
                        setPageSize={(pageSize: number) => setSetting({ pageSize })}
                    ></TransactionList>
                )}
            </Stack>
        </VStack>
    ) : (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '20px !important', sm: '60px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Transactions
                </Text>
            )}

            <Stack minH="831px">
                {setting.filterType === TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER ||
                setting.filterType === TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER ? (
                    <TransactionOrderList
                        setting={setting}
                        pageData={transData}
                        setCurrentPage={(page: number) => setSetting({ page })}
                        setPageSize={(pageSize: number) => setSetting({ pageSize })}
                    >
                        <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px" zIndex="5">
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(0, breakLine)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(breakLine, Object.keys(TransRecordTypeMapping).length)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                        </Stack>
                    </TransactionOrderList>
                ) : (
                    <TransactionList
                        setting={setting}
                        pageData={transData}
                        setCurrentPage={(page: number) => setSetting({ page })}
                        setPageSize={(pageSize: number) => setSetting({ pageSize })}
                    >
                        <Stack direction={{ base: 'column', sm: 'row' }} spacing="20px" zIndex="5">
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(0, breakLine)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                            <HStack spacing={{ base: '5px', sm: '20px' }}>
                                {Object.keys(TransRecordTypeMapping)
                                    .slice(breakLine, Object.keys(TransRecordTypeMapping).length)
                                    .map((e: string, i: number) => {
                                        return typeBt(e, i);
                                    })}
                            </HStack>
                        </Stack>
                    </TransactionList>
                )}
            </Stack>
        </VStack>
    );
};
