import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { config } from '../../../config/announceBar/config';
import { DEFAULT_CHAIN_ID } from '../../../config/bizConfig';

export interface AnnounceBarState {
    showBar: boolean[],
    chainId: number,
}

export const announceBar = createModel<RootModel>()({
    state: {
        showBar: new Array(config.length).fill(true),
        chainId: DEFAULT_CHAIN_ID,
    } as AnnounceBarState,
    reducers: {
        setShowBar: (state: AnnounceBarState, show:boolean, index:number) => produce(state, draft => {
            draft.showBar[index] = show;
        }),
        setChainId: (state: AnnounceBarState, chainId: number) => produce(state, draft => {
            draft.chainId = chainId;
        }),
    },
});
