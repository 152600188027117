import React from 'react';
import { Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { StatsBlock } from './components/StatsBlock';

const Stats: React.FC = () => {
    return (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%" pb={{ base: '150px', sm: '50px' }}>
                    <StatsBlock></StatsBlock>
                </Stack>
            }
        />
    );
};

export default Stats;
