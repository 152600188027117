import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef, useState } from 'react';
import Web3 from 'web3';
import { provider } from 'web3-core';
import chains, { BaseChain, getChain } from '../config/chains';
import { ChainId } from '../types/mod';
import { HttpProviderOptions } from 'web3-core-helpers';
import { useChain } from './useChain';
import { DEFAULT_CHAIN_ID } from '../config/bizConfig';

export interface Web3WithOfflineChainData {
    web3: Web3;
    account?: string | null;
    chainId: ChainId;
    walletChainId?: ChainId;
    chainName: string;
    chainIcon: string;
    provider: provider;
}

const ONLINE_CONTEXT = {} as { web3?: Web3; provider?: provider };

const OFFLINE_CONTEXT: { [index: number]: Web3 } = {};

const getWeb3FromContext = (library: provider, offlineChainId: ChainId): Web3 => {
    if (library) {
        if (ONLINE_CONTEXT.web3 !== undefined && ONLINE_CONTEXT.provider === library) {
            return ONLINE_CONTEXT.web3;
        }
        console.log('new web3 by provider: ', library);
        ONLINE_CONTEXT.web3 = new Web3(library);
        ONLINE_CONTEXT.provider = library;
        // print revert reason
        ONLINE_CONTEXT.web3.eth.handleRevert = true;
        return ONLINE_CONTEXT.web3;
    } else {
        if (!(offlineChainId in OFFLINE_CONTEXT)) {
            console.log('new offline provider for chain:', offlineChainId);
            const httpProvider = new Web3.providers.HttpProvider(getChain(offlineChainId)!.rpcUrl, {
                timeout: 10000,
            } as HttpProviderOptions);
            OFFLINE_CONTEXT[offlineChainId] = new Web3(httpProvider);
        }
        return OFFLINE_CONTEXT[offlineChainId];
    }
};

export const useWeb3WithDefault = (): Web3WithOfflineChainData => {
    const { account, library } = useWeb3React();
    const { chainId: walletChainId } = useChain();
    const chainId = walletChainId ?? DEFAULT_CHAIN_ID;
    const refEth = useRef(library);
    const [web3, setWeb3] = useState(getWeb3FromContext(library, chainId));

    useEffect(() => {
        if (library !== refEth.current || !library) {
            setWeb3(getWeb3FromContext(library, chainId));
            refEth.current = library;
        }
    }, [library, chainId]);

    // TODO use map
    const chain = chains.all.find((e) => {
        return e.id === chainId;
    }) as BaseChain;

    return {
        web3,
        account,
        chainId: chainId,
        walletChainId: walletChainId,
        chainName: chain.name,
        chainIcon: chain.icon,
        provider: library,
    };
};
