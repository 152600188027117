export type TimeNumberSeries = number[][];

export type NamedTimeNumberSeries = {
    name: string;
    data: TimeNumberSeries;
};

export type DataSeries = NamedTimeNumberSeries[];

export type OverviewInfo = {
    tvlLastedDay: number;
    volLastedDay: number;
    feesLastedDay: number;

    tvlDayOnDayRatio?: number;
    volDayOnDayRatio?: number;
    feesDayOnDayRatio?: number;

    volLastedWeek?: number;
    feesLastedWeek?: number;

    volWeekOnWeekRatio?: number;
    feesWeekOnWeekRatio?: number;

    tokenXSymbol?: string;
    tokenYSymbol?: string;
    tokenXAddress?: string;
    tokenYAddress?: string;
    amountX?: number;
    amountY?: number;
};

export enum TimeScope {
    day30 = 30 * 24 * 3600 * 1000,
    day45 = 45 * 24 * 3600 * 1000,
    day90 = 90 * 24 * 3600 * 1000,
}
