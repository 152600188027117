import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Icon, Image, Stack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import Card from '../../components/Card/Card';
import { i_text_copy, i_text_copy_bold } from '../../style';
import { DEFAULT_TIME_FORMAT, TokenSymbol } from '../../types/mod';
import { getColorThemeSelector } from '../../utils/funcs';
import { viewAddress, viewTrans } from '../../utils/blockchainExplorerUtils';
import { FeeRate } from './FeeRate';
import { TokenIcons } from './TokenIcons';
import { Pagination } from '../../components/Pagination/Pagination';
import { FiExternalLink } from 'react-icons/fi';
import { formatNumber } from '../../utils/tokenMath';
import truncateWalletAddress from '../../utils/truncateWalletAddress';
import { ResponseIziTransRecord, TransRecordTypeEnum } from '../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import moment from 'moment';
import { useChain } from '../../hooks/useChain';
import { useTokenList } from '../../hooks/useTokenConfig';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import { MultiPageProps } from '../../net/iZUMi-endpoints/src/restful/types/multiPage';
import truncateString from '../../utils/truncateString';

type TransactionListProps = MultiPageProps<ResponseIziTransRecord> & BoxProps;

export const TransactionList: React.FC<TransactionListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const { chainId } = useChain();
    const { getTokenInfo: tokenInfo } = useTokenList();
    const history = useHistory();

    const jumpPoolDetail = (e: ResponseIziTransRecord) => {
        history.push(`/pool?chainId=${e.chainId}&poolAddress=${e.address}`);
    };

    const getActionCell = (r: ResponseIziTransRecord): string => {
        const tokenXT = truncateString(r.tokenX);
        const tokenYT = truncateString(r.tokenY);
        switch (r.type) {
            case TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER:
                return `Inc order ${r.sellXEarnY ? tokenXT : tokenYT} to ${r.sellXEarnY ? tokenYT : tokenXT}`;
            case TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER:
                return `Dec order ${r.sellXEarnY ? tokenXT : tokenYT} to ${r.sellXEarnY ? tokenYT : tokenXT}`;
            case TransRecordTypeEnum.IZI_SWAP_TOKEN:
                return `Swap ${r.sellXEarnY ? tokenXT : tokenYT} to ${r.sellXEarnY ? tokenYT : tokenXT}`;
            case TransRecordTypeEnum.IZI_SWAP_INC_LIQ:
                return `Inc liquidity ${tokenXT}/${tokenYT}`;
            case TransRecordTypeEnum.IZI_SWAP_DEC_LIQ:
                return `Dec liquidity ${tokenXT}/${tokenYT}`;
            default:
                return 'unknown';
        }
    };

    const getActionDetailCell = (r: ResponseIziTransRecord): ReactElement => {
        const TokenX = r.sellXEarnY ? r.tokenX : r.tokenY;
        const TokenY = r.sellXEarnY ? r.tokenY : r.tokenX;

        const AmountX = r.sellXEarnY ? (r.amountX as number) : (r.amountY as number);
        const AmountY = r.sellXEarnY ? (r.amountY as number) : (r.amountX as number);
        const ImageX =
            process.env.PUBLIC_URL +
            (tokenInfo(r.chainId, r.sellXEarnY ? r.tokenX_address : r.tokenY_address)?.icon ?? '/assets/tokens/default.svg');
        const ImageY =
            process.env.PUBLIC_URL +
            (tokenInfo(r.chainId, r.sellXEarnY ? r.tokenY_address : r.tokenX_address)?.icon ?? '/assets/tokens/default.svg');
        switch (r.type) {
            case TransRecordTypeEnum.IZI_SWAP_TOKEN:
                return (
                    <Stack direction={{ base: 'column', sm: 'row' }} spacing={{ base: '12px', sm: '8px' }} minW="105px">
                        <HStack>
                            <Image boxSize="15px" src={ImageX}></Image>
                            <Text fontWeight={{ base: '700', sm: '500' }} ml="6px" w="120px" whiteSpace="nowrap">
                                {formatNumber(AmountX, 2, 2, 2)}
                                <Text as="span" fontWeight="500" ml="8px">
                                    {truncateString(TokenX)}
                                </Text>
                            </Text>
                        </HStack>
                        {!isMobile && <Image src="/assets/Liquidity/rightArrow.svg" ml="6px" w="30px"></Image>}
                        <HStack ml="0px !important">
                            <Image boxSize="15px" src={ImageY} ml={{ base: '0px !important', sm: '14px !important' }}></Image>

                            <Text ml="6px" fontWeight={{ base: '700', sm: '500' }} whiteSpace="nowrap">
                                {' '}
                                {formatNumber(AmountY, 2, 2, 2)}{' '}
                            </Text>
                            <Text ml="3px"> {truncateString(TokenY)}</Text>
                        </HStack>
                    </Stack>
                );
            case TransRecordTypeEnum.IZI_SWAP_INC_LIQ:
            case TransRecordTypeEnum.IZI_SWAP_DEC_LIQ:
                return (
                    <Stack direction={{ base: 'column', sm: 'row' }}>
                        <HStack>
                            <Image
                                boxSize="15px"
                                src={
                                    process.env.PUBLIC_URL + (tokenInfo(r.chainId, r.tokenX_address)?.icon ?? '/assets/tokens/default.svg')
                                }
                            ></Image>
                            <Text ml="6px" w="120px">
                                {formatNumber(r.amountX as any, 2, 5, 2)} {truncateString(r.tokenX)}
                            </Text>
                        </HStack>
                        <Text mx="6px">+</Text>
                        <HStack ml="0px !important">
                            <Image
                                boxSize="15px"
                                src={
                                    process.env.PUBLIC_URL + (tokenInfo(r.chainId, r.tokenY_address)?.icon ?? '/assets/tokens/default.svg')
                                }
                                ml={{ base: '0px !important', sm: '20px !important' }}
                            ></Image>
                            <Text ml="6px"> {formatNumber(r.amountY as any, 2, 5, 2)} </Text>
                            <Text ml="3px"> {truncateString(r.tokenY)}</Text>
                        </HStack>
                    </Stack>
                );
            case TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER:
            case TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER:
                return (
                    <Stack
                        color={colorTheme('#7F4AFE', '#B08EFF')}
                        className={i_text_copy_bold}
                        fontSize="13px"
                        alignItems={{ base: 'start', sm: 'center' }}
                        direction={{ base: 'column', sm: 'row' }}
                    >
                        <HStack>
                            <Text className={i_text_copy} fontSize="13px" mr="5px">
                                {'sell'}
                            </Text>

                            <Image
                                boxSize="15px"
                                mr="6px"
                                src={
                                    process.env.PUBLIC_URL + tokenInfo(r.chainId, r.sellXEarnY ? r.tokenX_address : r.tokenY_address)?.icon
                                }
                                fallbackSrc="/assets/tokens/default.svg"
                            ></Image>

                            <Text className={i_text_copy} fontSize="13px">
                                {formatNumber(r.amount ?? 0, 2, 5, 2)} {r.sellXEarnY ? truncateString(r.tokenX) : truncateString(r.tokenY)}
                                {' ' + '@' + ' '}
                                {formatNumber(r.sellXEarnY ? r.price ?? 0 : 1 / (r.price ?? 1), 2, 5, 2)}
                            </Text>
                        </HStack>

                        <HStack ml="0px !important">
                            <Image
                                boxSize="15px"
                                ml="8px"
                                src={
                                    process.env.PUBLIC_URL + tokenInfo(r.chainId, r.sellXEarnY ? r.tokenY_address : r.tokenX_address)?.icon
                                }
                                fallbackSrc="/assets/tokens/default.svg"
                            ></Image>
                            <Text className={i_text_copy} fontSize="13px" mr="5px">
                                {'/'}
                            </Text>
                            <Image
                                boxSize="15px"
                                mr="6px"
                                src={
                                    process.env.PUBLIC_URL + tokenInfo(r.chainId, r.sellXEarnY ? r.tokenX_address : r.tokenY_address)?.icon
                                }
                                fallbackSrc="/assets/tokens/default.svg"
                            ></Image>
                        </HStack>
                    </Stack>
                );

            default:
                return <></>;
        }
    };

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
        w: { base: '50px', sm: 'unset' },
        textAlign: { base: 'end', sm: 'unset' },
        mr: { base: '10px', sm: 'unset' },
    } as unknown as BoxProps;

    const tab = [
        ['Name', '220px'],
        ['Action', '210px'],
        ['Details', '330px'],
        ['Account', '120px'],
        ['Time', '150px'],
    ];
    return isMobile ? (
        <Card variant="base" w="100%" alignItems="left" py="15px" textAlign="left" mb="60px !important" {...rest}>
            <VStack spacing="20px" alignItems="left">
                {children}

                {pageData.data.map((item, i) => {
                    return (
                        <Stack key={i} direction={{ base: 'column', sm: 'row' }} spacing="15px">
                            <HStack
                                w="100%"
                                mt="2px !important"
                                px={{ base: '30px', sm: '20px' }}
                                cursor="pointer"
                                onClick={() => jumpPoolDetail(item)}
                            >
                                {isMobile && (
                                    <Text color={colorTheme('primary.300', 'gray.0')} fontSize="16px">
                                        {i + 1}
                                    </Text>
                                )}
                                <TokenIcons
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    initialToggle={false}
                                    chainId={item.chainId}
                                    showChainIcon={true}
                                />
                                <FeeRate
                                    tokenA={item.tokenX as TokenSymbol}
                                    tokenB={item.tokenY as TokenSymbol}
                                    feeTier={item.fee}
                                    initialToggle={false}
                                    truncS={3}
                                />
                            </HStack>

                            <Stack
                                bg={colorTheme(
                                    'linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)',
                                    'rgba(106, 106, 106, 0.05)'
                                )}
                                spacing="20px"
                                px="53px"
                                py="13px"
                            >
                                <HStack w={{ base: 'unset', sm: '210px' }} cursor="pointer">
                                    <Text {...trStyle}>{tab[1][0]}</Text>
                                    <HStack>
                                        <Text color={colorTheme('#7F4AFE', '#B08EFF')} className={i_text_copy_bold} fontSize="12px">
                                            {getActionCell(item)}
                                        </Text>
                                        <Icon
                                            as={FiExternalLink}
                                            boxSize="16px"
                                            onClick={() => viewTrans(item.chainId, item.txHash)}
                                            color={colorTheme('#7F4AFE', '#B08EFF')}
                                        />
                                    </HStack>
                                </HStack>

                                <HStack
                                    w={{ base: 'unset', sm: '330px' }}
                                    className={i_text_copy}
                                    fontSize="13px"
                                    alignItems={{ base: 'start', sm: 'center' }}
                                >
                                    <Text {...trStyle}>{tab[2][0]}</Text>
                                    {getActionDetailCell(item)}
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '120px' }} mt={{ base: '20px !important', sm: '-2px !important' }}>
                                    <Text {...trStyle}>{tab[3][0]}</Text>
                                    <HStack>
                                        <Text className={i_text_copy_bold} fontSize="13px">
                                            {truncateWalletAddress(item.account, 6, 3)}
                                        </Text>
                                        <Icon as={FiExternalLink} onClick={() => viewAddress(item.chainId, item.account)} boxSize="16px" />
                                    </HStack>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '150px' }} mt={{ base: '20px !important', sm: '-3px !important' }}>
                                    <Text {...trStyle}>{tab[4][0]}</Text>
                                    <Text className={i_text_copy} fontSize="13px">
                                        {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                    </Text>
                                </HStack>
                            </Stack>
                        </Stack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt={{ base: '30px !important', sm: '40px !important' }}
                    mx={{ base: '40px !important', sm: '0px !important' }}
                    mb={{ base: '30px !important', sm: '0px !important' }}
                />
            </VStack>
        </Card>
    ) : (
        <Card variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" textAlign="left" mb="60px !important" {...rest}>
            <VStack spacing="20px" alignItems="left">
                {children}
                <HStack spacing="20px" mt="30px !important">
                    {tab.map((e: any, i: any) => {
                        return (
                            <Text key={i} pl={i === 0 ? '20px' : 'unset'} {...trStyle} w={e[1]}>
                                {e[0]}
                            </Text>
                        );
                    })}
                </HStack>
                <Divider />
                {pageData.data.map((item, i) => {
                    return (
                        <Stack key={i} direction={{ base: 'column', sm: 'row' }} spacing="20px">
                            <HStack
                                w="220px"
                                mt="2px !important"
                                pl={{ base: '0px !important', sm: '20px !important' }}
                                cursor="pointer"
                                onClick={() => jumpPoolDetail(item)}
                            >
                                <TokenIcons
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    initialToggle={false}
                                    chainId={item.chainId}
                                    showChainIcon={true}
                                />
                                <FeeRate
                                    tokenA={item.tokenX as TokenSymbol}
                                    tokenB={item.tokenY as TokenSymbol}
                                    feeTier={item.fee}
                                    initialToggle={false}
                                    truncS={3}
                                />
                            </HStack>

                            <HStack w={{ base: 'unset', sm: '210px' }} cursor="pointer">
                                <Text color={colorTheme('#7F4AFE', '#B08EFF')} className={i_text_copy_bold} fontSize="12px">
                                    {getActionCell(item)}
                                </Text>
                                <Icon
                                    as={FiExternalLink}
                                    boxSize="16px"
                                    onClick={() => viewTrans(item.chainId, item.txHash)}
                                    color={colorTheme('#7F4AFE', '#B08EFF')}
                                />
                            </HStack>

                            <HStack
                                w={{ base: 'unset', sm: '330px' }}
                                className={i_text_copy}
                                fontSize="13px"
                                alignItems={{ base: 'start', sm: 'center' }}
                            >
                                {getActionDetailCell(item)}
                            </HStack>

                            <HStack w={{ base: 'unset', sm: '120px' }} mt={{ base: '20px !important', sm: '-2px !important' }}>
                                <Text className={i_text_copy_bold} fontSize="13px">
                                    {truncateWalletAddress(item.account, 6, 3)}
                                </Text>
                                <Icon as={FiExternalLink} onClick={() => viewAddress(item.chainId, item.account)} boxSize="16px" />
                            </HStack>

                            <HStack w={{ base: 'unset', sm: '150px' }} mt={{ base: '20px !important', sm: '-3px !important' }}>
                                <Text className={i_text_copy} fontSize="13px">
                                    {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                </Text>
                            </HStack>
                        </Stack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="40px !important"
                />
            </VStack>
        </Card>
    );
};
