import { BoxProps, Text, useColorMode, Box, Button, Flex, HStack, Stack, ChakraProps } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../utils/funcs';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Card from '../../components/Card/Card';
import { useCallback, useEffect, useState } from 'react';
import { formatNumber } from '../../utils/tokenMath';
import { i_h2, i_text_copy } from '../../style';
import { DataSeries, TimeScope } from './types';
import produce from 'immer';
import { Loading, LoadingEnum } from './Loading';

type VolumeChartProps = {
    height?: string;
    width?: string;
    variant?: string;
    series: DataSeries;
    title?: string;
    timeScope?: TimeScope;
    setTimeScope?: (timeScope: TimeScope) => void;
    networkLoading?: boolean;
    isNumber?: boolean;
    titleProps?: ChakraProps;
    chartProps?: ChakraProps;
} & BoxProps;

export const VolumeChart: React.FC<VolumeChartProps> = (props) => {
    const { height, series, variant, width, title, timeScope, setTimeScope, networkLoading, isNumber, titleProps, chartProps, ...rest } =
        props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [value, setValue] = useState([0, 0]);
    useEffect(() => {
        const seriesMaxIdx = series[0].data.length - 1;
        if (series && seriesMaxIdx >= 0) {
            setValue(series[0].data[seriesMaxIdx]);
        }
    }, [series]);

    const [chartOptions, setChartOptions] = useState<ApexOptions>({
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        yaxis: {
            show: false,
            title: {
                text: 'Volume',
                style: {
                    color: colorTheme('#3A2F53', '#FCA318'),
                },
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#FCA318'),
                },
            },
        },
        colors: [colorTheme('#6A20B3', '#FCA318')],

        xaxis: {
            type: 'datetime',
            axisBorder: {
                show: true,
                color: colorTheme('#52466A', '#FCA318'),
            },
            labels: {
                show: true,
                style: {
                    colors: colorTheme('#3A2F53', '#fff'),
                },
                datetimeUTC: false,
            },
            tooltip: { enabled: true },
        },
        tooltip: {
            x: {
                show: true,
                format: 'dd MMM yyyy',
            },
            theme: colorTheme('light', 'dark'),
            custom: () => {
                return '';
            },
            intersect: false,
        },
        grid: {
            show: true,
            borderColor: colorTheme('light', 'dark'),
        },
        dataLabels: {
            enabled: false,
        },
        chart: {
            toolbar: {
                show: false,
            },
            events: {
                mouseMove: (e, d, c) => {
                    const series = c.config.series;
                    const seriesMaxIdx = series[0].data.length - 1;

                    if (seriesMaxIdx === -1) {
                        setValue([0, 0]);
                    } else if (c.dataPointIndex === -1) {
                        setValue(series[0].data[seriesMaxIdx]);
                    } else {
                        setValue(series[0].data[Math.min(c.dataPointIndex, seriesMaxIdx)]);
                    }
                },
            },
        },
    });

    const setXaxisLimit = useCallback((minTime, maxTime) => {
        setChartOptions(
            produce((draft) => {
                (draft.xaxis as ApexXAxis).min = minTime;
                (draft.xaxis as ApexXAxis).max = maxTime;
            })
        );
    }, []);

    const setYaxisLimit = useCallback((min, max) => {
        setChartOptions(
            produce((draft) => {
                (draft.yaxis as ApexYAxis).min = 0;
                (draft.yaxis as ApexYAxis).max = max;
            })
        );
    }, []);

    useEffect(() => {
        if (!timeScope || series.length === 0 || networkLoading) {
            return;
        }

        const data = series[0].data;
        const seriesMaxIdx = data.length - 1;
        if (data && seriesMaxIdx >= 0) {
            const maxTime = data[seriesMaxIdx][0];
            const minTime = Math.max(maxTime - timeScope, data[0][0]);
            setXaxisLimit(minTime, maxTime);
            const minYData = data.reduce((min, d) => (d[0] > minTime ? Math.min(min, d[1]) : min), 10 ** 18); //large enough
            const maxYData = data.reduce((max, d) => (d[0] > minTime ? Math.max(max, d[1]) : max), 0);
            setYaxisLimit(minYData * 0.9, maxYData * 1.1);
        }
    }, [timeScope, series, networkLoading]);

    return (
        <Card variant={variant ?? 'base'} w="100%" position="relative" {...rest}>
            <Text position="absolute" left="16px" top="10px" className={i_text_copy} fontSize="16px" color="gray.400">
                {title ? title : 'Volume'}
            </Text>

            <Loading
                w={{ base: '100%' }}
                position="absolute"
                top="49%"
                hidden={networkLoading ? false : true}
                variant={LoadingEnum.purple}
                text={'Loading...'}
            ></Loading>

            <Stack hidden={networkLoading ? true : false}>
                {timeScope && (
                    <HStack position="absolute" right="16px" top="10px" zIndex="1">
                        <Flex className={i_text_copy} ml="auto">
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={timeScope === TimeScope.day45 ? '#7F4AFE' : 'unset'}
                                onClick={() => {
                                    setTimeScope && setTimeScope(TimeScope.day45);
                                }}
                            >
                                45d
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={timeScope === TimeScope.day90 ? '#7F4AFE' : 'unset'}
                                onClick={() => {
                                    setTimeScope && setTimeScope(TimeScope.day90);
                                }}
                            >
                                90d
                            </Button>
                        </Flex>
                    </HStack>
                )}
                <Text
                    position="absolute"
                    left="16px"
                    top="30px"
                    className={i_h2}
                    fontSize={{ base: '24px', sm: '32px' }}
                    color={colorTheme('gray.800', 'gray.0')}
                    zIndex="1"
                    {...titleProps}
                >
                    {isNumber ? formatNumber(value[1], 2, 2, 1) : '$' + formatNumber(value[1], 2, 2, 1)}
                </Text>

                <Box w="100%" mt="14px !important" {...chartProps}>
                    <ReactApexChart options={chartOptions} series={series} height={height ?? '300px'} width={width ?? '100%'} type="bar" />
                </Box>
            </Stack>
        </Card>
    );
};
