export const config = {
    pages: [
        {
            name: 'Dashboard',
            img: 'Dashboard.svg',
            address: '/dashboard',
            children: [],
        },
        {
            name: 'Pools',
            img: 'Pool.svg',
            address: '/pools',
            children: [],
        },
        {
            name: 'Tokens',
            img: 'Token.svg',
            address: '/tokens',
            children: [],
        },
        {
            name: 'Transactions',
            img: 'Transaction.svg',
            address: '/transactions',
            children: [],
        },
        {
            name: 'Liquidity',
            img: 'Liquidity.svg',
            address: '/liquidity',
            children: [],
        },
        {
            name: 'Stats',
            img: 'Stats.svg',
            address: '/stats',
            children: [],
        },
    ],
} as { pages: PageConfig[] };
