import { BoxProps, VStack, Text, Stack } from '@chakra-ui/react';
import { i_h2 } from '../../../style';
import { TVLChart } from '../../components/TVLChart';
import { VolumeChart } from '../../components/VolumeChart';
import { useEffect, useState } from 'react';
import { useChain } from '../../../hooks/useChain';
import { TimeNumberSeries, TimeScope } from '../../components/types';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapSummaryNormalRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';

type OverviewsProps = BoxProps;

export const StatsBlock: React.FC<OverviewsProps> = (props) => {
    const { ...rest } = props;
    const { chainId } = useChain();

    const [networkLoading, setNetworkLoading] = useState(false);
    const [tvlHistory, setTvlHistory] = useState<TimeNumberSeries>([]);
    const [volHistory, setVolHistory] = useState<TimeNumberSeries>([]);
    const [txHistory, setTxHistory] = useState<TimeNumberSeries>([]);
    const [uniqueUserHistory, setUniqueUserHistory] = useState<TimeNumberSeries>([]);
    const [newUserHistory, setNewUserHistory] = useState<TimeNumberSeries>([]);
    const [feesHistory, setFeesHistory] = useState<TimeNumberSeries>([]);

    const [tvlTimeScope, setTvlTimeScope] = useState<TimeScope>(TimeScope.day45);
    const [selectTimeScope, setSelectTimeScope] = useState<TimeScope>(TimeScope.day45);
    const [txTimeScope, setTxTimeScope] = useState<TimeScope>(TimeScope.day45);
    const [uniqueUserTimeScope, setUniqueUserTimeScope] = useState<TimeScope>(TimeScope.day45);
    const [newUserTimeScope, setNewUserTimeScope] = useState<TimeScope>(TimeScope.day45);
    const [feesTimeScope, setFeesTimeScope] = useState<TimeScope>(TimeScope.day45);

    useEffect(() => {
        setNetworkLoading(true);
        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_OF_CHAIN_DAY_ARCHIVE,
            page_size: 90,
            order_by: '-time',
        }).then((r) => {
            const poolHistory = r.data.is_success ? r.data.data : [];
            setTvlHistory(poolHistory.map((r) => [r.timestamp * 1000, r.tvl]).sort((a, b) => a[0] - b[0]));
            setVolHistory(poolHistory.map((r) => [r.timestamp * 1000, r.volDay]).sort((a, b) => a[0] - b[0]));
            setTxHistory(poolHistory.map((r) => [r.timestamp * 1000, r.swapDayCount]).sort((a, b) => a[0] - b[0]));
            setUniqueUserHistory(poolHistory.map((r) => [r.timestamp * 1000, r.uniqueDayUser]).sort((a, b) => a[0] - b[0]));
            setNewUserHistory(
                poolHistory
                    .map((r) => [r.timestamp * 1000, r.uniqueDayNewUser])
                    .filter((data) => data[1] !== undefined)
                    .sort((a, b) => a[0] - b[0])
            );
            setFeesHistory(poolHistory.map((r) => [r.timestamp * 1000, r.feesDay]).sort((a, b) => a[0] - b[0]));
            setNetworkLoading(false);
        });
    }, [chainId]);

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt={{ base: '20px', sm: '30px' }} spacing="20px" {...rest}>
            <Text className={i_h2} fontSize="18px">
                Stats
            </Text>

            <Stack w="100%" spacing={{ base: '17px', sm: '2%' }} direction={{ base: 'column', sm: 'row' }}>
                <TVLChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="220px"
                    series={[
                        {
                            name: 'TVL',
                            data: tvlHistory,
                        },
                    ]}
                    networkLoading={networkLoading}
                    timeScope={tvlTimeScope}
                    setTimeScope={setTvlTimeScope}
                    titleProps={{ fontSize: { base: '24px', sm: '28px' }, letterSpacing: '-0.02em' }}
                    chartProps={{ mb: '-10px !important' }}
                    justifyContent="end"
                />
                <VolumeChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="220px"
                    series={[
                        {
                            name: 'Volume',
                            data: volHistory,
                        },
                    ]}
                    networkLoading={networkLoading}
                    timeScope={selectTimeScope}
                    setTimeScope={setSelectTimeScope}
                    titleProps={{ fontSize: { base: '24px', sm: '28px' }, letterSpacing: '-0.02em' }}
                    chartProps={{ mb: '-10px !important' }}
                    justifyContent="end"
                />
            </Stack>
            <Stack w="100%" spacing={{ base: '17px', sm: '2%' }} direction={{ base: 'column', sm: 'row' }} justifyContent="end">
                <VolumeChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="220px"
                    title="Daily Fees"
                    series={[
                        {
                            name: 'Fees',
                            data: feesHistory,
                        },
                    ]}
                    networkLoading={networkLoading}
                    timeScope={feesTimeScope}
                    setTimeScope={setFeesTimeScope}
                    titleProps={{ fontSize: { base: '24px', sm: '28px' }, letterSpacing: '-0.02em' }}
                    chartProps={{ mb: '-10px !important' }}
                    justifyContent="end"
                />
                <VolumeChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="220px"
                    title="Daily Tx Num"
                    series={[
                        {
                            name: 'Tx_Num',
                            data: txHistory,
                        },
                    ]}
                    isNumber={true}
                    networkLoading={networkLoading}
                    timeScope={txTimeScope}
                    setTimeScope={setTxTimeScope}
                    titleProps={{ fontSize: { base: '24px', sm: '28px' }, letterSpacing: '-0.02em' }}
                    chartProps={{ mb: '-10px !important' }}
                    justifyContent="end"
                />
            </Stack>
            <Stack w="100%" spacing={{ base: '17px', sm: '2%' }} direction={{ base: 'column', sm: 'row' }}>
                <VolumeChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="220px"
                    title="Daily Users"
                    series={[
                        {
                            name: 'Unique Users',
                            data: uniqueUserHistory,
                        },
                    ]}
                    isNumber={true}
                    networkLoading={networkLoading}
                    timeScope={uniqueUserTimeScope}
                    setTimeScope={setUniqueUserTimeScope}
                    titleProps={{ fontSize: { base: '24px', sm: '28px' }, letterSpacing: '-0.02em' }}
                    chartProps={{ mb: '-10px !important' }}
                    justifyContent="end"
                />
                <VolumeChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="220px"
                    title="Daily New Users"
                    series={[
                        {
                            name: 'New Users',
                            data: newUserHistory,
                        },
                    ]}
                    isNumber={true}
                    networkLoading={networkLoading}
                    timeScope={newUserTimeScope}
                    setTimeScope={setNewUserTimeScope}
                    titleProps={{ fontSize: { base: '24px', sm: '28px' }, letterSpacing: '-0.02em' }}
                    chartProps={{ mb: '-10px !important' }}
                    justifyContent="end"
                />
            </Stack>
        </VStack>
    );
};
