import React from 'react';
import { Text, useColorMode, BoxProps, Flex, VStack, Image, ImageProps } from '@chakra-ui/react';
import { i_text_d } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import { useTranslation } from 'react-i18next';

export enum LoadingEnum {
    purple = 'purple',
}

type Props = {
    text?: string;
    variant?: LoadingEnum;
    imgProps?: ImageProps;
    textProps?: BoxProps;
} & BoxProps;

export const Loading: React.FC<Props> = ({ text, variant, imgProps, textProps, ...rest }) => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return variant === LoadingEnum.purple ? (
        <VStack justifyContent="center" {...rest}>
            <Image
                boxSize="20px"
                src="/assets/loading/purpleLoading.gif"
                fallbackSrc="/assets/loading/purpleLoading.gif"
                {...imgProps}
            ></Image>
            <Text className={i_text_d} color={colorTheme('#7A62B4', 'tertiary.400')} mt="16px" ml="12px" {...textProps}>
                {text ? text : t('Loading') + '...'}
            </Text>
        </VStack>
    ) : (
        <Flex direction="column" alignItems="center" justifyContent="center" pt="40px" {...rest}>
            <img width="20px" height="20px" src="/assets/loading/purpleLoading.gif" />
            <Text className={i_text_d} color={colorTheme('tertiary.600', 'tertiary.400')} mt="16px" ml="12px">
                {text ? text : t('Loading') + '...'}
            </Text>
        </Flex>
    );
};
