import { getScanUrl } from '../config/chains';
import { links } from '../config/links';
import { ChainId } from '../types/mod';

export const viewTrans = (chainId: ChainId, tx?: string) => window.open(`${getScanUrl(chainId)}tx/${tx?.toLowerCase()}`, '_blank');

export const viewAddress = (chainId: ChainId, addr?: string) =>
    window.open(`${getScanUrl(chainId)}address/${addr?.toLowerCase()}`, '_blank');

export const tradeAddress = (chainId: ChainId, tokenFromAddr: string, tokenToAddr: string) => {
    const suffix = `trade/swap?tokenFrom=${tokenFromAddr}&tokenTo=${tokenToAddr}&chainId=${chainId}`;
    process.env.REACT_APP_ENV === 'development'
        ? window.open(links.alphaLink + suffix, '_blank')
        : window.open('https://bitswap.site/' + suffix, '_blank');
};

export const tradeTokenAddress = (chainId: ChainId, addr: string) => {
    window.open(
        process.env.REACT_APP_ENV === 'development'
            ? '' //`https://alpha.izumi.finance/trade/swap?tokenFrom=${addr}&chainId=${chainId}`
            : `https://bitswap.site/trade/swap?tokenFrom=${addr}&chainId=${chainId}`,
        '_blank'
    );
};
